<template>
  <div class="flex flex-col lg:pt-24">
    <div class="lg:hidden">
      <BPageLoader v-if="isLoading" class="flex-grow" />
      <div
        class="flex flex-grow flex-col p-4"
        v-else-if="isOneTime && !hasNotExpired"
      >
        <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
          <button @click="handlePrev">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Edit Fundraiser</h2>
        </header>
        <div class="flex-grow flex flex-col items-center justify-center mt-10">
          <span class="p-6 bg-bg-alternate-3 rounded-full">
            <ResultEmptyIcon class="fill-current h-10" />
          </span>
          <h4 class="font-medium text-md mt-8">Fundraiser Expired</h4>
        </div>
      </div>
      <component
        v-else
        :is="
          project.fundraiserType == 'ONEOFF'
            ? componentName
            : componentNamePermanent
        "
        @next="handleNext"
        @prev="handlePrev"
        :project="project"
      />

    </div>

    <div class="hidden lg:flex justify-center ">
      <div class="b-card pt-6">
        <BPageLoader v-if="isLoading" class="flex-grow" />
        <div
          class="flex flex-grow flex-col p-4"
          v-else-if="isOneTime && !hasNotExpired"
        >
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="handlePrev">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Edit Fundraiser</h2>
          </header>
          <div class="flex-grow flex flex-col items-center justify-center mt-10">
            <span class="p-6 bg-bg-alternate-3 rounded-full">
              <ResultEmptyIcon class="fill-current h-10" />
            </span>
            <h4 class="font-medium text-md mt-8">Fundraiser Expired</h4>
          </div>
        </div>
        <component
          v-else
          :is="
            project.fundraiserType == 'ONEOFF'
              ? componentName
              : componentNamePermanent
          "
          @next="handleNext"
          @prev="handlePrev"
          :project="project"
        />

      </div>

    </div>
    
  </div>
</template>

<script>
import { ref, watch, computed, toRefs } from 'vue';
import ProjectDetailsOne from './ProjectDetailsOne';
import ProjectDetailsTwo from './ProjectDetailsTwo';
import ProjectDetailsPermanentOne from './Permanent/ProjectDetailsOne.vue';
import ProjectDetailsPermanentTwo from './Permanent/ProjectDetailsTwo.vue';
import ProjectDetailsPermanentThree from './Permanent/ProjectDetailsThree.vue';
import { useRoute, useRouter } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { getFundraiser } from '@/services/api';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);

export default {
  name: 'EditFundraiser',

  components: {
    ProjectDetailsOne,
    ProjectDetailsTwo,
    ProjectDetailsPermanentOne,
    ProjectDetailsPermanentTwo: ProjectDetailsPermanentTwo,
    ProjectDetailsPermanentThree,
  },

  setup() {
    let active = ref(1);
    const componentName = computed(() => {
      switch (active.value) {
        case 1:
          return ProjectDetailsOne;
        case 2:
          return ProjectDetailsTwo;
        default:
          throw Error('Invalid active component');
      }
    });

    const componentNamePermanent = computed(() => {
      switch (active.value) {
        case 1:
          return ProjectDetailsPermanentOne;
        case 2:
          return ProjectDetailsPermanentTwo;
        case 3:
          return ProjectDetailsPermanentThree;
        default:
          throw Error('Invalid active component');
      }
    });

    const project = ref({});
    const router = useRouter();
    const handleNext = (data) => {
      if (data) {
        project.value = { ...project.value, ...data };
      }
      active.value += 1;
    };

    const handlePrev = (data) => {
      if (active.value === 1) return router.go(-1);
      if (data) {
        project.value = { ...project.value, ...data };
      }
      active.value -= 1;
    };

    const route = useRoute();
    const { id: fundraiserId } = route.params;

    const [response, fetchFundraiser] = useApi(getFundraiser);
    watch(response, ({ isSuccess, data }) => {
      if (isSuccess) {
        project.value = data;
      }
    });

    fetchFundraiser(fundraiserId);

    const hasNotExpired = computed(() => {
      if(!response.data){
        return false
      }
      const { createdAt, duration } = response.data;
      const dueDay = dayjs(createdAt).add(duration, 'day');
      return dayjs(dueDay).isSameOrAfter(dayjs());
    });

    const isOneTime = computed(
      () => response.data?.fundraiserType !== 'RECURRING'
    );

    return {
      ...toRefs(response),
      hasNotExpired,
      isOneTime,
      componentName,
      componentNamePermanent,
      handleNext,
      project,
      handlePrev,
    };
  },
};
</script>
