<template>
  <div>
    <div class="lg:hidden">
      <div class="pt-8">
        <div class="px-4 py-4">
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="handlePrev">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Edit Permanent Fundraiser</h2>
          </header>
          <!-- {{ authUser.firstname }} -->
          <form class="my-10" @submit.prevent="handleSubmit">
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Title</label>
                <BToolTipBtn :details="infoData.title" />
              </div>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-4
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                :disabled="true"
                type="text"
                :value="authUser.firstname"
                maxlength="50"
              />
              <p class="text-xs font-medium text-right" style="color: #fd5f5f">
                {{ 50 - title?.length }}
              </p>
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>About us</label>
                <BToolTipBtn :details="infoData.about" />
              </div>
              <!-- <textarea
          class="
            border
            rounded
            border-border-primary
            my-2
            p-4
            text-sm
            font-medium
            focus:outline-none
            input-shadow
          "
          type="text"
          v-model.trim="description"
          maxlength="1000"
        /> -->
              <!-- {{description}} -->
              <vue-editor
                id="input-plan-english-description"
                v-model="description"
                @text-change="onTextChange"
                ref="editor"
                type="text"
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                placeholder="Enter notes here..."
                rows="3"
                max-rows="6"
                required
              />
              <p class="text-xs font-medium text-right" style="color: #fd5f5f">
                {{ 2500 - description?.length }}
              </p>
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Category</label>
                <BToolTipBtn :details="infoData.category" />
              </div>

              <BSelectInput
                class="my-2"
                :options="categoryList"
                v-model="category"
              />
            </div>

            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Fundraiser Cycle</label>
                <BToolTipBtn :details="infoData.cycle" />
              </div>
              <BModalSelect class="my-2" :options="cycleList" v-model="cycle" />
            </div>

            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Cycle Target Amount</label>
                <BToolTipBtn :details="infoData.targetCycle" />
              </div>
              <input
                class="my-6 rounded h-1 border border-border-primary slider"
                type="range"
                :min="100000"
                :max="10000000"
                :step="100000"
                v-model.number="targetAmount"
              />
              <span
                class="
                  rounded
                  border border-border-primary
                  px-4
                  py-3.5
                  min-w-36
                  max-w-min
                  font-medium
                "
              >
                ₦{{ targetAmount.toLocaleString() }}
              </span>
            </div>
            <div class="my-6">
              <label class="text-xs flex items-center my-3 font-medium">
                <input type="checkbox" v-model="shouldHideCycleAmount" />
                <span class="pl-3"
                  >Hide cycle target amount from the public.</span
                >
                <BToolTipBtn :details="infoData.hideCycle" />
              </label>
            </div>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                my-4
                font-bold
                text-sm
                xs:text-base
              "
              type="submit"
              :disabled="!isFilled"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card pt-8">
        <div class="px-26 py-4">
          <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
            <button @click="handlePrev">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">Edit Permanent Fundraiser</h2>
          </header>
          <!-- {{ category }} -->
          <form class="my-10" @submit.prevent="handleSubmit">
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Title</label>
                <BToolTipBtn :details="infoData.title" />
              </div>
              <input
                class="
                  border
                  rounded
                  border-border-primary
                  mt-2
                  mb-1
                  p-4
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                :disabled="true"
                type="text"
                :value="authUser.firstname"
                maxlength="50"
              />
              <p class="text-xs font-medium text-right" style="color: #fd5f5f">
                {{ 50 - title?.length }}
              </p>
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>About us</label>
                <BToolTipBtn :details="infoData.about" />
              </div>
              <!-- <textarea
          class="
            border
            rounded
            border-border-primary
            my-2
            p-4
            text-sm
            font-medium
            focus:outline-none
            input-shadow
          "
          type="text"
          v-model.trim="description"
          maxlength="1000"
        /> -->
              <!-- {{description}} -->
              <vue-editor
                id="input-plan-english-description"
                v-model="description"
                @text-change="onTextChange"
                ref="editor"
                type="text"
                class="
                  border
                  rounded
                  border-border-primary
                  my-2
                  p-
                  text-sm
                  font-medium
                  focus:outline-none
                  input-shadow
                "
                placeholder="Enter notes here..."
                rows="3"
                max-rows="6"
                required
              />
              <p class="text-xs font-medium text-right" style="color: #fd5f5f">
                {{ 2500 - description?.length }}
              </p>
            </div>
            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Category</label>
                <BToolTipBtn :details="infoData.category" />
              </div>

              <BSelectInput
                class="my-2"
                :options="categoryList"
                v-model="category"
              />
            </div>

            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Fundraiser Cycle</label>
                <BToolTipBtn :details="infoData.cycle" />
              </div>
              <BModalSelect class="my-2" :options="cycleList" v-model="cycle" />
            </div>

            <div
              class="flex flex-col my-6 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Cycle Target Amount</label>
                <BToolTipBtn :details="infoData.targetCycle" />
              </div>
              <input
                class="my-6 rounded h-1 border border-border-primary slider"
                type="range"
                :min="100000"
                :max="10000000"
                :step="100000"
                v-model.number="targetAmount"
              />
              <span
                class="
                  rounded
                  border border-border-primary
                  px-4
                  py-3.5
                  min-w-36
                  max-w-min
                  font-medium
                "
              >
                ₦{{ targetAmount.toLocaleString() }}
              </span>
            </div>
            <div class="my-6">
              <label class="text-xs flex items-center my-3 font-medium">
                <input type="checkbox" v-model="shouldHideCycleAmount" />
                <span class="pl-3"
                  >Hide cycle target amount from the public.</span
                >
                <BToolTipBtn :details="infoData.hideCycle" />
              </label>
            </div>
            <button
              class="
                bg-btn-bg-primary
                text-btn-text-primary
                rounded
                w-full
                py-4
                my-4
                font-bold
                text-sm
                xs:text-base
              "
              type="submit"
              :disabled="!isFilled"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue3-editor';
import { reactive, toRefs, computed } from 'vue';
import { useAppState } from '@/cmp-functions/appState.js';
import BModalSelect from '@/components/ui/BModalSelect';
import infoData from '@/data/info.js';
import BToolTipBtn from '@/components/ui/BToolTipBtn';
import BSelectInput from '../../../components/ui/BSelectInput.vue';
import { useStore } from 'vuex';

const durationList = [
  '7 days',
  '14 days',
  '30 days',
  '90 days' /*'180 days', '365 days' */,
];
const cycleList = ['Monthly', 'Quarterly'];

const recurringData = {
  shouldHideCycleAmount: false,
  targetAmount: 100000,
};

export default {
  name: 'ProjectDetailsOne',

  emits: ['next', 'prev'],

  props: ['project'],

  components: {
    BModalSelect,
    BToolTipBtn,
    VueEditor,
    BSelectInput,
  },
  methods: {
    onTextChange() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > 2500) {
        console.log(len);
        quill.deleteText(2500, len);
      }
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const authUser = computed(() => store.state.auth.userDetails);
    const { categories } = useAppState();

    const capitalizeString = (input) => {
      return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
    };
    const formInputData = {
      description: props.project.fundraiserDesc,
      category: props.project.category?.categoryname,
      cycle: capitalizeString(props.project.fundraiserCycle),
      targetAmount: props.project.targetAmount,
    };
    delete props.project.category

    const initFormInputs = {
      ...formInputData,
      ...recurringData,
      title: authUser.value.firstname,
    };

    const formInputs = reactive(
      Object.keys(initFormInputs).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : initFormInputs[next],
        }),
        {}
      )
    );
    const handleSubmit = () => {
      const categoryId = categories.data.find(
        (c) => c.categoryname === formInputs.category
      )?.id;
      emit('next', { ...formInputs, categoryId });
    };

    const handlePrev = () => {
      emit('prev', formInputs);
    };

    const categoryList = computed(() => {
      return categories.data.map(({ categoryname }) => categoryname);
    });

    const isFilled = computed(() =>
      Object.keys(initFormInputs).every(
        (key) => formInputs[key] !== null && formInputs[key] !== ''
      )
    );

    return {
      ...toRefs(formInputs),
      infoData,
      authUser,
      handleSubmit,
      isFilled,
      handlePrev,
      categoryList,
      durationList,
      cycleList,
      capitalizeString,
    };
  },
};
</script>
