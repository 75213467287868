<template>
  <div id="form">
    <BSpinner
      v-if="
        isCompressing ||
        fundraiserResponse.isLoading ||
        galleryResponse.isLoading
      "
    />

    <ProjectImageCrop
      v-if="cropImage"
      :file="file"
      @save="handleSave"
      @cancel="handleCancel"
    />

    <div v-else>
      <div class="px-6 py-4 flex flex-col lg:hidden">
        <header class="font-semibold text-lg flex text-text-alternate-1 pr-6">
          <button @click="handlePrev">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">Edit Fundraiser</h2>
        </header>
        <form class="my-8" @submit.prevent="handleSubmit">
          <div
            class="flex flex-col my-3 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label>Add Images</label>
              <BToolTipBtn :details="infoData.images" />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                my-2
                overflow-hidden
                w-full
                input-shadow
              "
            >
              <AttachmentIcon class="mx-5 h-6.5 fill-current" />
              <label
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  text-text-alternate-3
                  opacity-70
                "
                >Choose image (jpg, jpeg, png) (Max. 1MB)
                <input
                  type="file"
                  @change="handleFileInput"
                  hidden
                  accept="image/png,image/jpeg,image/jpg"
                />
              </label>
            </div>
            <p class="font-medium text-text-alternate-3 text-xs">
              Add at least 1 image (You may add up to 3)
            </p>
            <span
              v-for="error in v$.images.$errors"
              :key="error.$uid"
              class="text-xs text-text-danger"
              >{{ error.$message }}</span
            >
            <p
              class="text-xs text-text-danger font-semibold"
              v-if="images.length < 1"
            >
              Please upload atleast 1 image
            </p>
          </div>
          <!-- <div class="overflow-x-auto py-1" v-if="images.length">
            <div class="inline-flex gap-3">
              <div
                class="w-72 relative rounded-md overflow-hidden"
                v-for="({ imageurl, base64 }, index) of images"
                :key="index"
              >
                <img :src="base64" alt="image" v-if="base64" />
                <img :src="imageurl" alt="image" v-else />
              </div>
            </div>
          </div> -->
          <div class="overflow-x-auto py-1" v-if="images.length">
            <div class="inline-flex gap-3">
              <div
                class="w-72 relative rounded-md overflow-hidden"
                v-for="(link, index) of images"
                :key="index"
              >
                <button
                  class="
                    text-2xl
                    bg-bg-primary
                    rounded-full
                    overflow-hidden
                    h-5
                    w-5
                    absolute
                    z-10
                    right-4
                    top-4
                  "
                  @click.prevent="
                    link.isFeatured
                      ? toast.error('You cannot remove the Featured Image')
                      : removeImage(index)
                  "
                >
                  <span class="inline-block transform -translate-y-1">
                    &times;
                  </span>
                </button>
                <img :src="link.imageurl || link" alt="image" />
                <div
                  class="
                    absolute
                    bottom-0
                    h-10
                    flex
                    justify-between
                    items-center
                    w-full
                    bg-bg-alternate-3
                    text-sm
                    font-medium
                    px-3
                    text-text-primary
                    bg-opacity-50
                  "
                >
                  <p>Mark as featured Image</p>

                  <input
                    type="checkbox"
                    :checked="link.isFeatured"
                    :class="{
                      'bg-bg-primary pointer-events-none': link.isFeatured,
                    }"
                    @change="
                      (link.isFeatured = link.isFeatured),
                        setFeaturedImage(index)
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col my-4 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label
                >Add YouTube video link
                <span class="font-medium">(optional)</span></label
              >
              <BToolTipBtn :details="infoData.youtube" />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                input-shadow
              "
            >
              <YTIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                :value="youTubeLink"
              />
            </div>
          </div>
          <div
            class="
              relative
              rounded-md
              h-48
              2xs:h-52
              xs:h-64
              md:h-72
              overflow-hidden
            "
            v-if="embededYoutubeId"
          >
            <iframe
              :src="`https://www.youtube.com/embed/${embededYoutubeId}`"
              width="100%"
              height="100%"
            />
          </div>

          <template v-if="isOneTime">
            <div
              class="flex flex-col my-4 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Are you the beneficiary of this fundraiser?</label>
                <BToolTipBtn :details="infoData.isBeneficiary" />
              </div>
              <div class="flex gap-4">
                <label
                  class="
                    border
                    flex-grow
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-4
                    text-sm
                    font-semibold
                    focus:outline-none
                    input-shadow
                    flex
                    items-center
                  "
                >
                  <input
                    class="mr-3"
                    type="radio"
                    v-model="isOrganiserBeneficiary"
                    :value="true"
                  />
                  <span>Yes</span>
                </label>
                <label
                  class="
                    border
                    flex-grow
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-4
                    text-sm
                    font-semibold
                    focus:outline-none
                    input-shadow
                    flex
                    items-center
                  "
                >
                  <input
                    class="mr-3"
                    type="radio"
                    v-model="isOrganiserBeneficiary"
                    :value="false"
                  />
                  <span>No</span>
                </label>
              </div>
            </div>

            <template v-if="!isOrganiserBeneficiary">
              <div
                class="
                  flex flex-col
                  my-4
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Name of beneficiary</label>
                  <BToolTipBtn :details="infoData.beneficiaryName" />
                </div>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-4
                    text-sm
                    font-semibold
                    focus:outline-none
                    input-shadow
                  "
                  placeholder="Full name"
                  type="text"
                  :value="beneficiary.fullname"
                />
              </div>

              <div
                class="
                  flex flex-col
                  my-4
                  text-text-primary
                  font-semibold
                  text-sm
                "
              >
                <div class="flex">
                  <label>Link to beneficiary</label>
                  <BToolTipBtn :details="infoData.beneficiaryLink" />
                </div>
                <input
                  class="
                    border
                    rounded
                    border-border-primary
                    mt-2
                    mb-1
                    p-4
                    text-sm
                    font-semibold
                    focus:outline-none
                    input-shadow
                  "
                  type="text"
                  placeholder="https://"
                  :value="beneficiary.link"
                />
                <p class="text-text-alternate-3 text-xs font-medium mt-1">
                  This could be a link to a public profile, article or post
                  about the beneficiary
                </p>
              </div>
            </template>
          </template>

          <div
            class="flex flex-col my-5 text-text-primary font-semibold text-sm"
            v-if="isOneTime"
          >
            <div class="flex">
              <label>Invite Observers (Optional)</label>
              <BToolTipBtn :details="infoData.observers" />
            </div>
            <div class="my-2">
              <BFilterableSelectInput
                v-model="memberUsername"
                :seachMethod="searchUsers"
                label="name"
              />
            </div>

            <div v-if="members.length > 1">
              <template v-for="({ userdetail, memberType }, index) in members">
                <div
                  class="
                    flex
                    gap-2
                    xs:gap-3
                    rounded
                    justify-between
                    items-center
                    border border-border-primary
                    text-xs
                    px-4
                    py-2.5
                    xs:py-3
                    my-2
                    font-semibold
                  "
                  :key="`${userdetail.userid}-${index}`"
                  v-if="memberType !== 'BENEFICIARY'"
                >
                  <div
                    class="
                      rounded-full
                      bg-bg-alternate-3
                      text-sm
                      xs:text-base
                      h-8
                      xs:h-10
                      w-8
                      xs:w-10
                      flex flex-shrink-0
                      justify-center
                      items-center
                    "
                    :style="{
                      backgroundColor: randomColor({
                        luminosity: 'light',
                        seed: userdetail.firstname[0],
                      }),
                    }"
                  >
                    {{ userdetail.firstname[0] }}
                  </div>
                  <div class="w-full">
                    <p class="flex xs:text-sm">
                      <span
                        class="
                          text-text-primary
                          w-10
                          flex-grow
                          max-w-min
                          truncate
                        "
                        >{{ userdetail.firstname }}
                        {{ userdetail.lastname }}</span
                      >
                      <span class="ml-2 text-text-alternate-3 truncate"
                        >@{{ userdetail.username }}</span
                      >
                    </p>
                    <p class="text-text-alternate-4 mt-0.5 capitalize">
                      {{ memberType.toLowerCase() }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div
            class="flex flex-col my-5 text-text-primary font-semibold text-sm"
          >
            <div class="flex">
              <label
                >Social Media Links
                <span class="font-medium">(optional)</span></label
              >
              <BToolTipBtn :details="infoData.socials" />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                mt-2
                input-shadow
              "
            >
              <FBIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="facebook"
              />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                input-shadow
              "
            >
              <IGIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="instagram"
              />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                input-shadow
              "
            >
              <TWIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="twitter"
              />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                input-shadow
              "
            >
              <YTIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="youtube"
              />
            </div>
            <div
              class="
                border
                rounded
                border-border-primary
                flex
                items-center
                overflow-hidden
                w-full
                my-1
                input-shadow
              "
            >
              <TKKIcon class="mx-3.5 h-6.5" />
              <input
                class="
                  p-4
                  w-full
                  border-l border-border-primary
                  text-sm
                  font-semibold
                  focus:outline-none
                "
                type="text"
                placeholder="https://"
                v-model.trim="tiktok"
              />
            </div>
          </div>

          <hr class="text-text-alternate-8" />

          <div
            class="
              rounded
              bg-bg-alternate-3
              p-4
              my-4
              relative
              text-text-primary
            "
          >
            <div class="flex justify-between">
              <h3 class="font-semibold mb-2 text-sm xs:text-base">
                Make Private
              </h3>
              <BToggle v-model="makePrivate" />
            </div>
            <p class="text-2xs xs:text-xs">
              Hide your fundraiser from the public. You can still share the link
              with anyone to recieve donations.
            </p>
          </div>

          <div
            class="
              rounded
              bg-bg-alternate-3
              p-4
              my-4
              relative
              text-text-primary
            "
          >
            <div class="flex justify-between">
              <h3 class="font-semibold mb-2 text-sm xs:text-base">
                Turn On/Off Comments
              </h3>
              <BToggle v-model="turnOnComments" />
            </div>
            <p class="text-2xs xs:text-xs">
              Turn on/off the ability for users to comment on your fundraiser.
            </p>
          </div>

          <div
            class="
              rounded
              bg-bg-alternate-3
              p-4
              my-4
              relative
              text-text-primary
            "
            v-if="!isOneTime"
          >
            <div class="flex justify-between items-center mb-4">
              <h3 class="font-semibold text-sm xs:text-base">
                Monthly Donation
              </h3>
              <button
                class="
                  focus:outline-none
                  border border-border-alternate-4
                  text-text-alternate-4
                  font-semibold
                  rounded-full
                  w-14
                  text-xs
                  py-0.5
                "
                @click.prevent="handleMonthlyButton"
              >
                {{ canEdit ? 'Save' : 'Edit' }}
              </button>
            </div>
            <p class="text-2xs xs:text-xs mt-2 mb-8">
              Solicit monthly donations from your donors by specifying
              subscription amounts. E.g. N1,000/Month
            </p>
            <div
              class="flex flex-col my-3 text-text-primary font-semibold text-sm"
            >
              <label class="text-xs">Silver Donor</label>
              <div class="flex my-2 w-full items-center input-shadow">
                <input
                  :class="[
                    'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                    { 'bg-bg-alternate-3': !canEdit },
                  ]"
                  :readonly="!canEdit"
                  type="number"
                  v-model.number="silverDonor"
                  pattern="\d*"
                  inputmode="decimal"
                  min="0"
                />
                <p class="ml-2 whitespace-nowrap">Per Month</p>
              </div>
            </div>
            <div
              class="flex flex-col my-3 text-text-primary font-semibold text-sm"
            >
              <label class="text-xs">Gold Donor</label>
              <div class="flex my-2 w-full items-center input-shadow">
                <input
                  :class="[
                    'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                    { 'bg-bg-alternate-3': !canEdit },
                  ]"
                  :readonly="!canEdit"
                  type="number"
                  v-model.number="goldDonor"
                  pattern="\d*"
                  inputmode="decimal"
                  min="0"
                />
                <p class="ml-2 whitespace-nowrap">Per Month</p>
              </div>
            </div>
            <div
              class="flex flex-col my-3 text-text-primary font-semibold text-sm"
            >
              <label class="text-xs">Diamond Donor</label>
              <div class="flex my-2 w-full items-center input-shadow">
                <input
                  :class="[
                    'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                    { 'bg-bg-alternate-3': !canEdit },
                  ]"
                  :readonly="!canEdit"
                  type="number"
                  v-model.number="diamondDonor"
                  pattern="\d*"
                  inputmode="decimal"
                  min="0"
                />
                <p class="ml-2 whitespace-nowrap">Per Month</p>
              </div>
            </div>
          </div>

          <div
            class="
              rounded
              bg-bg-alternate-3
              p-4
              my-4
              relative
              text-text-primary
            "
          >
            <div class="flex justify-between">
              <div class="flex mb-2">
                <h3 class="font-semibold text-sm xs:text-base">
                  Allow Promote and Earn
                </h3>
                <BToolTipBtn :details="infoData.promoteEarn" />
              </div>
              <BToggle v-model="allowPromoteAndEarn" />
            </div>
            <p class="text-2xs xs:text-xs">
              Allow people promote your fundraiser within their networks and
              earn commission on the amount they were able to raise.
            </p>
          </div>

          <div v-if="allowPromoteAndEarn">
            <div
              class="flex flex-col my-3 text-text-primary font-semibold text-sm"
            >
              <div class="flex">
                <label>Commission percentage (%)</label>
                <BToolTipBtn :details="infoData.commissionPercentage" />
              </div>
              <input
                class="rounded h-1 border border-border-primary slider my-6"
                type="range"
                placeholder="20"
                v-model.number="promoteAndEarnCommPercent"
                min="20"
                max="50"
                pattern="\d*"
                inputmode="decimal"
              />
              <span
                class="
                  rounded
                  border border-border-primary
                  px-4
                  py-3.5
                  min-w-36
                  max-w-min
                  font-medium
                  mb-3
                "
              >
                {{ promoteAndEarnCommPercent }}%
              </span>
              <p class="font-medium text-text-alternate-3 text-xs">
                Specify your Promote and Earn Commission percentage, should be
                between 20 and 50%
              </p>
            </div>
          </div>

          <div v-if="isRewarding">
            <div v-if="rewards.length">
              <div
                class="
                  rounded
                  border border-border-alternate-1
                  p-2
                  xs:py-4
                  my-2
                  relative
                  text-text-primary
                "
                v-for="(rewardValue, index) in rewards"
                :key="`${index}-${rewardValue.blessingAmount}`"
              >
                <div class="flex justify-between items-center mb-2 xs:pr-2">
                  <span
                    class="font-semibold text-xs xs:text-sm pl-2"
                    style="color: #577387"
                  >
                    ₦{{ rewardValue.blessingAmount.toLocaleString() }} or more
                  </span>
                </div>
                <p
                  class="text-2xs xs:text-xs text-text-primary font-medium px-2"
                >
                  {{ rewardValue.reward }}
                </p>
              </div>

              <hr class="text-text-alternate-8 my-5" />
            </div>
          </div>

          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              rounded
              w-full
              py-4
              mt-2
              font-bold
              text-sm
              xs:text-base
            "
            type="submit"
          >
            Save
          </button>
        </form>
      </div>

      <div class="hidden lg:flex justify-center pt-4">
        <div class="b-card pt-8">
          <div class="px-26 py-4">
            <div class="px-6 py-4 flex flex-col">
              <header
                class="font-semibold text-lg flex text-text-alternate-1 pr-6"
              >
                <button @click="handlePrev">
                  <LeftArrowIcon class="h-6 fill-current" />
                </button>
                <h2 class="mx-auto">Edit Fundraiser</h2>
              </header>
              <form class="my-8" @submit.prevent="handleSubmit">
                <div
                  class="
                    flex flex-col
                    my-3
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label>Add Images</label>
                    <BToolTipBtn :details="infoData.images" />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      my-2
                      overflow-hidden
                      w-full
                      input-shadow
                    "
                  >
                    <AttachmentIcon class="mx-5 h-6.5 fill-current" />
                    <label
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        text-text-alternate-3
                        opacity-70
                      "
                      >Choose image (jpg, jpeg, png) (Max. 1MB)
                      <input
                        type="file"
                        @change="handleFileInput"
                        hidden
                        accept="image/png,image/jpeg,image/jpg"
                      />
                    </label>
                  </div>
                  <p class="font-medium text-text-alternate-3 text-xs">
                    Add at least 1 image (You may add up to 3)
                  </p>
                  <span
                    v-for="error in v$.images.$errors"
                    :key="error.$uid"
                    class="text-xs text-text-danger"
                    >{{ error.$message }}</span
                  >
                  <p
                    class="text-xs text-text-danger font-semibold"
                    v-if="images.length < 1"
                  >
                    Please upload atleast 1 image
                  </p>
                </div>
                <!-- <div class="overflow-x-auto py-1" v-if="images.length">
                  <div class="inline-flex gap-3">
                    <div
                      class="w-72 relative rounded-md overflow-hidden"
                      v-for="({ imageurl, base64 }, index) of images"
                      :key="index"
                    >
                      <img :src="base64" alt="image" v-if="base64" />
                      <img :src="imageurl" alt="image" v-else />
                    </div>
                  </div>
                </div> -->
                <div class="overflow-x-auto py-1" v-if="images.length">
                  <div class="inline-flex gap-3">
                    <div
                      class="w-72 relative rounded-md overflow-hidden"
                      v-for="(link, index) of images"
                      :key="index"
                    >
                      <button
                        class="
                          text-2xl
                          bg-bg-primary
                          rounded-full
                          overflow-hidden
                          h-5
                          w-5
                          absolute
                          z-10
                          right-4
                          top-4
                        "
                        @click.prevent="
                          link.isFeatured
                            ? toast.error(
                                'You cannot remove the Featured Image'
                              )
                            : removeImage(index)
                        "
                      >
                        <span class="inline-block transform -translate-y-1">
                          &times;
                        </span>
                      </button>
                      <img :src="link.imageurl || link" alt="image" />
                      <div
                        class="
                          absolute
                          bottom-0
                          h-10
                          flex
                          justify-between
                          items-center
                          w-full
                          bg-bg-alternate-3
                          text-sm
                          font-medium
                          px-3
                          text-text-primary
                          bg-opacity-50
                        "
                      >
                        <p>Mark as featured Image</p>

                        <input
                          type="checkbox"
                          :class="{
                            'bg-bg-primary pointer-events-none':
                              link.isFeatured,
                          }"
                          :checked="link.isFeatured"
                          @change="
                            (link.isFeatured = link.isFeatured),
                              setFeaturedImage(index)
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    flex flex-col
                    my-4
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label
                      >Add YouTube video link
                      <span class="font-medium">(optional)</span></label
                    >
                    <BToolTipBtn :details="infoData.youtube" />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      overflow-hidden
                      w-full
                      my-1
                      input-shadow
                    "
                  >
                    <YTIcon class="mx-3.5 h-6.5" />
                    <input
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        focus:outline-none
                      "
                      type="text"
                      placeholder="https://"
                      :value="youTubeLink"
                    />
                  </div>
                </div>
                <div
                  class="
                    relative
                    rounded-md
                    h-48
                    2xs:h-52
                    xs:h-64
                    md:h-72
                    overflow-hidden
                  "
                  v-if="embededYoutubeId"
                >
                  <iframe
                    :src="`https://www.youtube.com/embed/${embededYoutubeId}`"
                    width="100%"
                    height="100%"
                  />
                </div>

                <template v-if="isOneTime">
                  <div
                    class="
                      flex flex-col
                      my-4
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <div class="flex">
                      <label>Are you the beneficiary of this fundraiser?</label>
                      <BToolTipBtn :details="infoData.isBeneficiary" />
                    </div>
                    <div class="flex gap-4">
                      <label
                        class="
                          border
                          flex-grow
                          rounded
                          border-border-primary
                          mt-2
                          mb-1
                          p-4
                          text-sm
                          font-semibold
                          focus:outline-none
                          input-shadow
                          flex
                          items-center
                        "
                      >
                        <input
                          class="mr-3"
                          type="radio"
                          v-model="isOrganiserBeneficiary"
                          :value="true"
                        />
                        <span>Yes</span>
                      </label>
                      <label
                        class="
                          border
                          flex-grow
                          rounded
                          border-border-primary
                          mt-2
                          mb-1
                          p-4
                          text-sm
                          font-semibold
                          focus:outline-none
                          input-shadow
                          flex
                          items-center
                        "
                      >
                        <input
                          class="mr-3"
                          type="radio"
                          v-model="isOrganiserBeneficiary"
                          :value="false"
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>

                  <template v-if="!isOrganiserBeneficiary">
                    <div
                      class="
                        flex flex-col
                        my-4
                        text-text-primary
                        font-semibold
                        text-sm
                      "
                    >
                      <div class="flex">
                        <label>Name of beneficiary</label>
                        <BToolTipBtn :details="infoData.beneficiaryName" />
                      </div>
                      <input
                        class="
                          border
                          rounded
                          border-border-primary
                          mt-2
                          mb-1
                          p-4
                          text-sm
                          font-semibold
                          focus:outline-none
                          input-shadow
                        "
                        placeholder="Full name"
                        type="text"
                        :value="beneficiary.fullname"
                      />
                    </div>

                    <div
                      class="
                        flex flex-col
                        my-4
                        text-text-primary
                        font-semibold
                        text-sm
                      "
                    >
                      <div class="flex">
                        <label>Link to beneficiary</label>
                        <BToolTipBtn :details="infoData.beneficiaryLink" />
                      </div>
                      <input
                        class="
                          border
                          rounded
                          border-border-primary
                          mt-2
                          mb-1
                          p-4
                          text-sm
                          font-semibold
                          focus:outline-none
                          input-shadow
                        "
                        type="text"
                        placeholder="https://"
                        :value="beneficiary.link"
                      />
                      <p class="text-text-alternate-3 text-xs font-medium mt-1">
                        This could be a link to a public profile, article or
                        post about the beneficiary
                      </p>
                    </div>
                  </template>
                </template>

                <div
                  class="
                    flex flex-col
                    my-5
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                  v-if="isOneTime"
                >
                  <div class="flex">
                    <label>Invite Observers (Optional)</label>
                    <BToolTipBtn :details="infoData.observers" />
                  </div>
                  <div class="my-2">
                    <BFilterableSelectInput
                      v-model="memberUsername"
                      :seachMethod="searchUsers"
                      label="name"
                    />
                  </div>

                  <div v-if="members.length > 1">
                    <template
                      v-for="({ userdetail, memberType }, index) in members"
                    >
                      <div
                        class="
                          flex
                          gap-2
                          xs:gap-3
                          rounded
                          justify-between
                          items-center
                          border border-border-primary
                          text-xs
                          px-4
                          py-2.5
                          xs:py-3
                          my-2
                          font-semibold
                        "
                        :key="`${userdetail.userid}-${index}`"
                        v-if="memberType !== 'BENEFICIARY'"
                      >
                        <div
                          class="
                            rounded-full
                            bg-bg-alternate-3
                            text-sm
                            xs:text-base
                            h-8
                            xs:h-10
                            w-8
                            xs:w-10
                            flex flex-shrink-0
                            justify-center
                            items-center
                          "
                          :style="{
                            backgroundColor: randomColor({
                              luminosity: 'light',
                              seed: userdetail.firstname[0],
                            }),
                          }"
                        >
                          {{ userdetail.firstname[0] }}
                        </div>
                        <div class="w-full">
                          <p class="flex xs:text-sm">
                            <span
                              class="
                                text-text-primary
                                w-10
                                flex-grow
                                max-w-min
                                truncate
                              "
                              >{{ userdetail.firstname }}
                              {{ userdetail.lastname }}</span
                            >
                            <span class="ml-2 text-text-alternate-3 truncate"
                              >@{{ userdetail.username }}</span
                            >
                          </p>
                          <p class="text-text-alternate-4 mt-0.5 capitalize">
                            {{ memberType.toLowerCase() }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div
                  class="
                    flex flex-col
                    my-5
                    text-text-primary
                    font-semibold
                    text-sm
                  "
                >
                  <div class="flex">
                    <label
                      >Social Media Links
                      <span class="font-medium">(optional)</span></label
                    >
                    <BToolTipBtn :details="infoData.socials" />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      overflow-hidden
                      w-full
                      my-1
                      mt-2
                      input-shadow
                    "
                  >
                    <FBIcon class="mx-3.5 h-6.5" />
                    <input
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        focus:outline-none
                      "
                      type="text"
                      placeholder="https://"
                      v-model.trim="facebook"
                    />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      overflow-hidden
                      w-full
                      my-1
                      input-shadow
                    "
                  >
                    <IGIcon class="mx-3.5 h-6.5" />
                    <input
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        focus:outline-none
                      "
                      type="text"
                      placeholder="https://"
                      v-model.trim="instagram"
                    />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      overflow-hidden
                      w-full
                      my-1
                      input-shadow
                    "
                  >
                    <TWIcon class="mx-3.5 h-6.5" />
                    <input
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        focus:outline-none
                      "
                      type="text"
                      placeholder="https://"
                      v-model.trim="twitter"
                    />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      overflow-hidden
                      w-full
                      my-1
                      input-shadow
                    "
                  >
                    <YTIcon class="mx-3.5 h-6.5" />
                    <input
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        focus:outline-none
                      "
                      type="text"
                      placeholder="https://"
                      v-model.trim="youtube"
                    />
                  </div>
                  <div
                    class="
                      border
                      rounded
                      border-border-primary
                      flex
                      items-center
                      overflow-hidden
                      w-full
                      my-1
                      input-shadow
                    "
                  >
                    <TKKIcon class="mx-3.5 h-6.5" />
                    <input
                      class="
                        p-4
                        w-full
                        border-l border-border-primary
                        text-sm
                        font-semibold
                        focus:outline-none
                      "
                      type="text"
                      placeholder="https://"
                      v-model.trim="tiktok"
                    />
                  </div>
                </div>

                <hr class="text-text-alternate-8" />

                <div
                  class="
                    rounded
                    bg-bg-alternate-3
                    p-4
                    my-4
                    relative
                    text-text-primary
                  "
                >
                  <div class="flex justify-between">
                    <h3 class="font-semibold mb-2 text-sm xs:text-base">
                      Make Private
                    </h3>
                    <BToggle v-model="makePrivate" />
                  </div>
                  <p class="text-2xs xs:text-xs">
                    Hide your fundraiser from the public. You can still share
                    the link with anyone to recieve donations.
                  </p>
                </div>

                <div
                  class="
                    rounded
                    bg-bg-alternate-3
                    p-4
                    my-4
                    relative
                    text-text-primary
                  "
                >
                  <div class="flex justify-between">
                    <h3 class="font-semibold mb-2 text-sm xs:text-base">
                      Turn On/Off Comments
                    </h3>
                    <BToggle v-model="turnOnComments" />
                  </div>
                  <p class="text-2xs xs:text-xs">
                    Turn on/off the ability for users to comment on your
                    fundraiser.
                  </p>
                </div>

                <div
                  class="
                    rounded
                    bg-bg-alternate-3
                    p-4
                    my-4
                    relative
                    text-text-primary
                  "
                  v-if="!isOneTime"
                >
                  <div class="flex justify-between items-center mb-4">
                    <h3 class="font-semibold text-sm xs:text-base">
                      Monthly Donation
                    </h3>
                    <button
                      class="
                        focus:outline-none
                        border border-border-alternate-4
                        text-text-alternate-4
                        font-semibold
                        rounded-full
                        w-14
                        text-xs
                        py-0.5
                      "
                      @click.prevent="handleMonthlyButton"
                    >
                      {{ canEdit ? 'Save' : 'Edit' }}
                    </button>
                  </div>
                  <p class="text-2xs xs:text-xs mt-2 mb-8">
                    Solicit monthly donations from your donors by specifying
                    subscription amounts. E.g. N1,000/Month
                  </p>
                  <div
                    class="
                      flex flex-col
                      my-3
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <label class="text-xs">Silver Donor</label>
                    <div class="flex my-2 w-full items-center input-shadow">
                      <input
                        :class="[
                          'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                          { 'bg-bg-alternate-3': !canEdit },
                        ]"
                        :readonly="!canEdit"
                        type="number"
                        v-model.number="silverDonor"
                        pattern="\d*"
                        inputmode="decimal"
                        min="0"
                      />
                      <p class="ml-2 whitespace-nowrap">Per Month</p>
                    </div>
                  </div>
                  <div
                    class="
                      flex flex-col
                      my-3
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <label class="text-xs">Gold Donor</label>
                    <div class="flex my-2 w-full items-center input-shadow">
                      <input
                        :class="[
                          'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                          { 'bg-bg-alternate-3': !canEdit },
                        ]"
                        :readonly="!canEdit"
                        type="number"
                        v-model.number="goldDonor"
                        pattern="\d*"
                        inputmode="decimal"
                        min="0"
                      />
                      <p class="ml-2 whitespace-nowrap">Per Month</p>
                    </div>
                  </div>
                  <div
                    class="
                      flex flex-col
                      my-3
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <label class="text-xs">Diamond Donor</label>
                    <div class="flex my-2 w-full items-center input-shadow">
                      <input
                        :class="[
                          'p-4 w-full border border-border-alternate-1 focus:outline-none rounded border-border-primary text-sm font-semibold text-text-alternate-3',
                          { 'bg-bg-alternate-3': !canEdit },
                        ]"
                        :readonly="!canEdit"
                        type="number"
                        v-model.number="diamondDonor"
                        pattern="\d*"
                        inputmode="decimal"
                        min="0"
                      />
                      <p class="ml-2 whitespace-nowrap">Per Month</p>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    rounded
                    bg-bg-alternate-3
                    p-4
                    my-4
                    relative
                    text-text-primary
                  "
                >
                  <div class="flex justify-between">
                    <div class="flex mb-2">
                      <h3 class="font-semibold text-sm xs:text-base">
                        Allow Promote and Earn
                      </h3>
                      <BToolTipBtn :details="infoData.promoteEarn" />
                    </div>
                    <BToggle v-model="allowPromoteAndEarn" />
                  </div>
                  <p class="text-2xs xs:text-xs">
                    Allow people promote your fundraiser within their networks
                    and earn commission on the amount they were able to raise.
                  </p>
                </div>

                <div v-if="allowPromoteAndEarn">
                  <div
                    class="
                      flex flex-col
                      my-3
                      text-text-primary
                      font-semibold
                      text-sm
                    "
                  >
                    <div class="flex">
                      <label>Commission percentage (%)</label>
                      <BToolTipBtn :details="infoData.commissionPercentage" />
                    </div>
                    <input
                      class="
                        rounded
                        h-1
                        border border-border-primary
                        slider
                        my-6
                      "
                      type="range"
                      placeholder="20"
                      v-model.number="promoteAndEarnCommPercent"
                      min="20"
                      max="50"
                      pattern="\d*"
                      inputmode="decimal"
                    />
                    <span
                      class="
                        rounded
                        border border-border-primary
                        px-4
                        py-3.5
                        min-w-36
                        max-w-min
                        font-medium
                        mb-3
                      "
                    >
                      {{ promoteAndEarnCommPercent }}%
                    </span>
                    <p class="font-medium text-text-alternate-3 text-xs">
                      Specify your Promote and Earn Commission percentage,
                      should be between 20 and 50%
                    </p>
                  </div>
                </div>

                <div v-if="isRewarding">
                  <div v-if="rewards.length">
                    <div
                      class="
                        rounded
                        border border-border-alternate-1
                        p-2
                        xs:py-4
                        my-2
                        relative
                        text-text-primary
                      "
                      v-for="(rewardValue, index) in rewards"
                      :key="`${index}-${rewardValue.blessingAmount}`"
                    >
                      <div
                        class="flex justify-between items-center mb-2 xs:pr-2"
                      >
                        <span
                          class="font-semibold text-xs xs:text-sm pl-2"
                          style="color: #577387"
                        >
                          ₦{{ rewardValue.blessingAmount.toLocaleString() }} or
                          more
                        </span>
                      </div>
                      <p
                        class="
                          text-2xs
                          xs:text-xs
                          text-text-primary
                          font-medium
                          px-2
                        "
                      >
                        {{ rewardValue.reward }}
                      </p>
                    </div>

                    <hr class="text-text-alternate-8 my-5" />
                  </div>
                </div>

                <button
                  class="
                    bg-btn-bg-primary
                    text-btn-text-primary
                    rounded
                    w-full
                    py-4
                    mt-2
                    font-bold
                    text-sm
                    xs:text-base
                  "
                  type="submit"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch, ref } from 'vue';
import BFilterableSelectInput from '@/components/ui/BFilterableSelectInput';
import BToggle from '@/components/ui/BToggle';
import { getEditProjectData, getYouTubeVideoId } from '@/utils';
import { useApi } from '@/cmp-functions/useApi';
import { useRouter } from 'vue-router';
import {
  updateFundraiser,
  searchUsers,
  postAnImage,
  deleteAnImage,
} from '@/services/api';
import BSpinner from '@/components/ui/BSpinner';
import randomColor from 'randomcolor';
import infoData from '@/data/info.js';
import BToolTipBtn from '@/components/ui/BToolTipBtn';
import ProjectImageCrop from '../createProject/ProjectImageCrop';
import imageCompression from 'browser-image-compression';
import { useToast } from 'vue-toastification';
import Swal from 'sweetalert2';
import useVuelidate from '@vuelidate/core';
import { required, helpers, minLength } from '@vuelidate/validators';

const data = {
  images: [],
  turnOnComments: true,
  rewards: [],
  makePrivate: false,
  allowPromoteAndEarn: false,
  promoteAndEarnCommPercent: '',
};

const optionals = {
  youTubeLink: '',
};

const socials = {
  Instagram: 'instagram',
  Facebook: 'facebook',
  Twitter: 'twitter',
  YouTube: 'youtube',
  Tiktok: 'tiktok',
};
const oneOffData = { members: [], isOrganiserBeneficiary: true };
const recurringData = {
  monthlydonations: [
    {
      donationtype: 'SILVER',
      amount: 100,
    },
    {
      donationtype: 'GOLD',
      amount: 200,
    },
    {
      donationtype: 'DIAMOND',
      amount: 500,
    },
  ],
};
const monthlyDonations = {
  SILVER: 'silverDonor',
  GOLD: 'goldDonor',
  DIAMOND: 'diamondDonor',
};

export default {
  name: 'ProjectDetailsTwo',

  emits: ['next', 'prev'],

  props: ['project'],

  components: {
    BFilterableSelectInput,
    BToggle,
    BSpinner,
    BToolTipBtn,
    ProjectImageCrop,
  },

  setup(props, { emit }) {
    const isCompressing = ref(false);
    const cropImage = ref(false);
    const router = useRouter();
    const toast = useToast();
    // const imageErrorMsg = ref('');
    const required$ = helpers.withMessage('This field is required', required);
    const isOneTime = props.project.fundraiserType !== 'PERMANENT';

    const initFormInputs = {
      // makePrivate: props.project.makePrivate,
      ...data,
      ...(isOneTime ? oneOffData : recurringData),
    };

    const socialLinks = Object.values(socials).reduce(
      (acc, v) => ({ ...acc, [v]: props.project[v] || '' }),
      {}
    );

    props.project.socialmedialinks.forEach(
      ({ smname, link }) =>
        (socialLinks[socials[smname]] = props.project[socials[smname]] || link)
    );

    const formInputs = reactive({
      ...socialLinks,
      ...Object.keys(optionals).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : optionals[next],
        }),
        {}
      ),
      ...Object.keys(initFormInputs).reduce(
        (acc, next) => ({
          ...acc,
          [next]:
            props.project[next] !== undefined
              ? props.project[next]
              : initFormInputs[next],
        }),
        {}
      ),
    });

    const donations = props.project.monthlydonations.reduce(
      (acc, { donationtype, amount }) => {
        return { ...acc, [monthlyDonations[donationtype]]: amount };
      },
      {}
    );

    const model = {
      images: formInputs.images,
    };
    const rules = {
      images: { required$, minLength: minLength(1), $each: { required } },
    };

    const v$ = useVuelidate(rules, model);

    const blessingForm = reactive({
      blessingAmount: '',
      reward: '',
      file: '',
      memberUsername: '',
      canEdit: false,
      isRewarding: props.project.rewards?.length > 0,
      ...donations,
    });

    watch(blessingForm, ({ isRewarding }) => {
      if (!isRewarding) {
        formInputs.rewards = [];
      }
    });

    const beneficiary = props.project.members.find(
      ({ memberType }) => memberType === 'BENEFICIARY'
    );

    const [fundraiserResponse, editFundraiser] = useApi(updateFundraiser);
    const [galleryResponse, postGallery] = useApi(postAnImage);
    const [deleteImageResponse, deleteGallery] = useApi(deleteAnImage);

    const slug = computed(() => {
      const { id, title } = props.project;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    watch(fundraiserResponse, ({ isSuccess }) => {
      toast.success('Fundraiser edited successfully and awaiting approval.');
      if (isSuccess) {
        router.push({ name: 'AccountsFundraiser' });
      }
    });

    watch(galleryResponse, ({ isSuccess, isFailure, data, error }) => {
      if (isSuccess) {
        formInputs.images.push({
          imageurl: data.imageurl,
          isFeatured: formInputs.images.length ? false : true,
        });
        blessingForm.file = null;
        toggleCropImage();
      } else if (isFailure) {
        toast.error(error);
      }
    });

    watch(deleteImageResponse, ({ isSuccess, isFailure, error }) => {
      if (isSuccess) {
        toast.success('Image deleted successfully!');
      } else if (isFailure) {
        toast.error(error);
      }
    });

    const handleSubmit = async () => {
      const isAtLeastOneFeatured = formInputs.images.some(
        (image) => image.isFeatured === true
      );
      const result = await v$.value.$validate();
      if (!result || formInputs.images?.length < 1 || !isAtLeastOneFeatured) {
        document.getElementById('form').scrollIntoView({
          behavior: 'smooth',
        });
        return;
      }
      const payload = getEditProjectData({ ...props.project, ...formInputs });
      editFundraiser({ id: props.project.id, payload });
    };

    const handlePrev = () => {
      emit('prev', formInputs);
    };

    const handleMonthlyButton = () => {
      if (blessingForm.canEdit) {
        formInputs.monthlydonations = formInputs.monthlydonations.map(
          (donation) => {
            return {
              ...donation,
              amount: blessingForm[monthlyDonations[donation.donationtype]],
            };
          }
        );
      }

      blessingForm.canEdit = !blessingForm.canEdit;
    };

    const toggleCropImage = () => {
      cropImage.value = !cropImage.value;
    };

    const handleReward = () => {
      const { blessingAmount, reward } = blessingForm;
      formInputs.rewards = [...formInputs.rewards, { blessingAmount, reward }];
      blessingForm.blessingAmount = '';
      blessingForm.reward = '';
    };

    const handleSave = async (image) => {
      const imageSize = Math.round(
        Buffer.from(image.substring(image.indexOf(',') + 1)).length / 1e3
      );
      if (imageSize > 1000) {
        toast.error(
          'Image size is too large, Maximum image size should be 1MB.'
        );
      } else {
        const { name } = blessingForm.file;
        postGallery({ file: { name, mimetype: 'image/jpeg', base64: image } });
      }
    };

    const handleFileInput = async ({ target }) => {
      // const check = await validateFileInput(target);
      // console.log('This is check: ', check);

      // if (!check) {
      //   return;
      // } else {
      // }
      try {
        isCompressing.value = true;
        let file = target.files[0];
        blessingForm.file = await imageCompression(file, { maxSizeMB: 0.05 });
        toggleCropImage();
      } catch (error) {
        console.log(error);
      } finally {
        isCompressing.value = false;
      }
    };

    const isFilled = computed(
      () =>
        Object.keys(initFormInputs).every(
          (key) => formInputs[key] !== '' && formInputs.images.length > 0
        ) &&
        !blessingForm.canEdit &&
        (blessingForm.isRewarding ? formInputs.rewards.length > 0 : true) &&
        (isOneTime
          ? formInputs.isOrganiserBeneficiary ||
            (beneficiary.fullname && beneficiary.link)
          : true)
    );

    const embededYoutubeId = computed(() =>
      getYouTubeVideoId(formInputs.youTubeLink)
    );

    const removeImage = (index) => {
      const imageToBeDeleted = formInputs.images.filter(
        (item, ind) => ind === index
      );
      const imageId = imageToBeDeleted[0].id;
      if (imageId) {
        Swal.fire({
          title: `<p class="text-lg">Delete Image?</p>`,
          text: `Are you sure you want to delete this image? This action is irreversible!`,
          // icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // User confirmed, do something here
            // Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
            deleteImage(index, imageId);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // User clicked cancel, do something here (or not)
            // Swal.fire('Cancelled', 'Your item is safe :)', 'error');
          }
        });
      } else {
        formInputs.images = formInputs.images.filter(
          (img, ind) => ind !== index
        );
      }
    };

    const deleteImage = (index, id) => {
      deleteGallery(props.project.id, id);
      formInputs.images = formInputs.images.filter((img, ind) => ind !== index);
    };

    const setFeaturedImage = (index) => {
      formInputs.images.forEach((image, ind) => {
        image.isFeatured = false;

        if (ind === index) {
          image.isFeatured = true;
        }
      });
    };

    return {
      ...toRefs(formInputs),
      ...toRefs(blessingForm),
      embededYoutubeId,
      beneficiary,
      isOneTime,
      searchUsers,
      randomColor,
      isFilled,
      handleSubmit,
      handleMonthlyButton,
      fundraiserResponse,
      handleReward,
      handlePrev,
      handleFileInput,
      infoData,
      isCompressing,
      cropImage,
      handleSave,
      galleryResponse,
      removeImage,
      setFeaturedImage,
      slug,
      v$,
      toast,
    };
  },
};
</script>
